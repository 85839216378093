import * as React from 'react';
import MaterialTable, { Action, Column } from '@material-table/core';
import { getJobSize, IJob, isJobActiveOrQueued, JobLink, JobOutcome, JobState, useJobActions, useJobReport } from '../Model/Job';
import { createDateColumn, dateToISOWithReducedPrecision } from '../utils/DateUtils';
import { TextField, Typography, Button, Grid, FormControlLabel, Checkbox, FormHelperText } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import FilterList from '@mui/icons-material/FilterList';
import { useSnackbar } from 'notistack';
import { EmailEllipsis, exportToJson, hasAdminOrViewOnlyAccess } from '../utils/Utils';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useAuthorizationContext } from '../utils/AuthorizationContext';
import { ThirtyDaysInMilliseconds } from '../utils/Constants';


export interface IJobOverviewProps {
    pageSize: number;
    pageSizeOptions: number[];
}

type FilterByDate = {
    filterByCreationDate: boolean;
    creationStartDate: Date;
    creationEndDate: Date;
    filterByStartDate: boolean;
    startStartDate: Date;
    startEndDate: Date;
    filterByEndDate: boolean;
    endStartDate: Date;
    endEndDate: Date;
};

type FieldError = {
    hasError: boolean;
    message?: string;
};

type Errors = {
    creationStartDate: FieldError;
    creationEndDate: FieldError;
    startStartDate: FieldError;
    startEndDate: FieldError;
    endStartDate: FieldError;
    endEndDate: FieldError;
};

type ErrorMapping = {
    [key: string]: Array<keyof Errors>;
};

const getDefaultDate = () => new Date(Date.now() - ThirtyDaysInMilliseconds);

const initialDateFilterState: FilterByDate = {
    filterByCreationDate: false,
    creationStartDate: getDefaultDate(),
    creationEndDate: new Date(),
    filterByStartDate: false,
    startStartDate: getDefaultDate(),
    startEndDate: new Date(),
    filterByEndDate: false,
    endStartDate: getDefaultDate(),
    endEndDate: new Date(),
};

const initialErrors: Errors = {
    creationStartDate: { hasError: false },
    creationEndDate: { hasError: false },
    startStartDate: { hasError: false },
    startEndDate: { hasError: false },
    endStartDate: { hasError: false },
    endEndDate: { hasError: false },
};

const errorMapping: ErrorMapping = {
    filterByCreationDate: ['creationStartDate', 'creationEndDate'],
    filterByStartDate: ['startStartDate', 'startEndDate'],
    filterByEndDate: ['endStartDate', 'endEndDate'],
};

const ErrorMessage: React.FC<{ error: FieldError }> = ({ error }) => (
    error.hasError ? (
        <FormHelperText error style={{ marginLeft: '16px', width: "100%" }}>
            {error.message}
        </FormHelperText>
    ) : <></>
);

export default function JobsOverview({ pageSize, pageSizeOptions }: IJobOverviewProps) {
    const { enqueueSnackbar } = useSnackbar();
    const jobActions = useJobActions();
    const jobReport = useJobReport(`startTime=${dateToISOWithReducedPrecision(getDefaultDate())}`);
    const [filterBarElWidth, setFilterBarElWidth] = React.useState(60);
    const [expanded, setExpanded] = React.useState(false);
    const [searchText, setSearchText] = React.useState<string>('');
    const [jobQuery, setJobQuery] = React.useState<string>('');

    const [filterByTerm, setFilterByTerm] = React.useState({
        jobIdTerm: "",
        ultimateSiteTerm: "",
        userEmailTerm: "",
        userIdTerm: "",
    });

    const [filterByDate, setFilterByDate] = React.useState<FilterByDate>(initialDateFilterState);

    const [filterByState, setFilterByState] = React.useState({
        filterNew: false,
        filterActive: false,
        filterQueued: false,
        filterSuccess: false,
        filterFailed: false,
        filterCancelled: false
    });

    const [errors, setErrors] = React.useState<Errors>(initialErrors);

    const frontendAccessLevel = useAuthorizationContext();
    const isAdminOrViewOnlyUser = hasAdminOrViewOnlyAccess(frontendAccessLevel);

    function inclusiveStartDate(date: Date): Date {
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        return (date);
    }

    function inclusiveEndDate(date: Date): Date {
        date.setHours(23);
        date.setMinutes(59);
        date.setSeconds(59);
        return (date);
    }


    const fetchJobsBasedOnFilter = () => {
        const params = new URLSearchParams();
        let states = [];
        let outcomes = [];

        // Validation logic
        if (Object.values(errors).some(error => error.hasError)) {
            return; // Stop submission if there are errors
        }

        //Filter by term
        if (filterByTerm.jobIdTerm.trim()) {
            params.append('jobId', filterByTerm.jobIdTerm);
        }

        if (filterByTerm.ultimateSiteTerm.trim()) {
            params.append('ultimateSite', filterByTerm.ultimateSiteTerm);
        }

        if (filterByTerm.userEmailTerm.trim()) {
            params.append('email', filterByTerm.userEmailTerm);
        }

        if (filterByTerm.userIdTerm.trim()) {
            params.append('userId', filterByTerm.userIdTerm);
        }

        //Filter by state
        if (filterByState.filterActive) {
            states.push(JobState.Active);
        }

        if (filterByState.filterNew) {
            states.push(JobState.New);
        }

        if (filterByState.filterQueued) {
            states.push(JobState.Queued);
        }

        if (states.length > 0) {
            params.append('state', states.join('|'));
        }

        //Filter by outcome (state-completed)
        if (filterByState.filterSuccess) {
            outcomes.push(JobOutcome.Success);
        }

        if (filterByState.filterFailed) {
            outcomes.push(JobOutcome.Failed);
        }

        if (filterByState.filterCancelled) {
            outcomes.push(JobOutcome.Cancelled);
        }

        if (outcomes.length > 0) {
            params.append('outcome', outcomes.join('|'));
        }

        //Filter by date
        if (filterByDate.filterByCreationDate) {
            params.append('startTime', dateToISOWithReducedPrecision(inclusiveStartDate(filterByDate.creationStartDate)))
            params.append('endTime', dateToISOWithReducedPrecision(inclusiveEndDate(filterByDate.creationEndDate)))
        }

        if (filterByDate.filterByStartDate) {
            params.append('executionStartTime', dateToISOWithReducedPrecision(inclusiveStartDate(filterByDate.startStartDate)))
            params.append('executionEndTime', dateToISOWithReducedPrecision(inclusiveEndDate(filterByDate.startEndDate)))
        }

        if (filterByDate.filterByEndDate) {
            params.append('finishStartTime', dateToISOWithReducedPrecision(inclusiveStartDate(filterByDate.endStartDate)))
            params.append('finishEndTime', dateToISOWithReducedPrecision(inclusiveEndDate(filterByDate.endEndDate)))
        }

        //Default to 30 days if no date filter is provided
        if (!filterByDate.filterByCreationDate && !filterByDate.filterByStartDate && !filterByDate.filterByEndDate) {
            params.append('startTime', dateToISOWithReducedPrecision(inclusiveStartDate(new Date(Date.now() - ThirtyDaysInMilliseconds))))
        }

        setJobQuery(params.toString());

        jobReport.refresh(params.toString());

    };

    const handleTurnOffFilter = (props: any) => {
        setFilterByTerm({
            jobIdTerm: "",
            ultimateSiteTerm: "",
            userEmailTerm: "",
            userIdTerm: "",
        });

        setFilterByDate({
            filterByCreationDate: false,
            creationStartDate: new Date(Date.now() - ThirtyDaysInMilliseconds),
            creationEndDate: new Date(),

            filterByStartDate: false,
            startStartDate: new Date(Date.now() - ThirtyDaysInMilliseconds),
            startEndDate: new Date(),

            filterByEndDate: false,
            endStartDate: new Date(Date.now() - ThirtyDaysInMilliseconds),
            endEndDate: new Date()
        });

        setFilterByState({
            filterNew: false,
            filterActive: false,
            filterQueued: false,
            filterSuccess: false,
            filterFailed: false,
            filterCancelled: false
        });

        setErrors(initialErrors);

        //Fetch default, last 30 days jobs
        jobReport.SetTimespan({
            startDate: new Date(Date.now() - ThirtyDaysInMilliseconds),
            endDate: new Date()
        });
    };

    const isValidDate = (date: any) => {
        // Check if date is valid and a Date object
        return date instanceof Date && !isNaN(date.getTime());
    };

    const handleTermFilterChange = (prop: any) => (event: { target: { value: any; }; }) => {
        setFilterByTerm({
            ...filterByTerm,
            [prop]: event.target.value,
        });
    };

    const handleStateCheckboxChange = (event: any) => {
        setFilterByState({ ...filterByState, [event.target.name]: event.target.checked });
    };

    const handleDateCheckboxChange = (event: any) => {
        const { name, checked } = event.target;

        setFilterByDate(prev => {
            const updatedFilter = { ...prev, [name]: checked };

            if (checked) {
                const fieldsToValidate = errorMapping[name] || [];
                setErrors(prev => {
                    const newErrors = { ...prev };

                    if (fieldsToValidate.length === 2) {
                        const [startField, endField] = fieldsToValidate;
                        const startDate = updatedFilter[startField];
                        const endDate = updatedFilter[endField];

                        const isValidStartDate = isValidDate(startDate);
                        const isValidEndDate = isValidDate(endDate);

                        if (!isValidStartDate) {
                            newErrors[startField] = {
                                hasError: true,
                                message: 'Invalid From date.'
                            };
                        }

                        if (!isValidEndDate) {
                            newErrors[endField] = {
                                hasError: true,
                                message: 'Invalid To date.'
                            };
                        }

                        if (!isValidStartDate || !isValidEndDate)
                            return newErrors;


                        if (endDate < startDate) {
                            newErrors[endField] = {
                                hasError: true,
                                message: 'To date must be after From date.'
                            };
                        }

                        if (startDate > endDate) {
                            newErrors[startField] = {
                                hasError: true,
                                message: 'From date must be before To date.'
                            };
                        }
                    }

                    return newErrors;
                });
            } else {
                // Reset the respective error states when the checkbox is unchecked
                resetErrorStates(name);
            }
            return updatedFilter;
        });
    };

    const resetErrorStates = (name: string) => {
        const fieldsToReset = errorMapping[name] || [];
        setErrors(prev => {
            const newErrors = { ...prev };
            fieldsToReset.forEach(field => {
                newErrors[field] = { hasError: false };
            });
            return newErrors;
        });
    };

    const handleDateChange = (field: keyof FilterByDate) => (date: Date | null) => {
        const isValid = isValidDate(date);

        setFilterByDate(prev => {
            const updatedFilter = { ...prev, [field]: date };

            for (const dateFields of Object.values(errorMapping)) {
                const [startField, endField] = dateFields;

                if (field === endField || field === startField) {
                    const startDate = updatedFilter[startField];
                    const endDate = updatedFilter[endField];

                    setErrors(prevErrors => {
                        const updatedErrors = { ...prevErrors };

                        if (field === startField) {
                            const startValid = isValid && startDate && startDate < endDate;

                            if (!isValid) {
                                updatedErrors[startField] = { hasError: true, message: "Invalid From date." };
                            } else if (!startValid) {
                                updatedErrors[startField] = {
                                    hasError: true,
                                    message: 'From date must be before To date.'
                                };
                            } else {
                                updatedErrors[startField] = { hasError: false };
                            }
                        }

                        if (field === endField) {
                            const endValid = isValid && endDate && endDate > startDate;

                            if (!isValid) {
                                updatedErrors[endField] = { hasError: true, message: "Invalid To date." };
                            } else if (!endValid) {
                                updatedErrors[endField] = {
                                    hasError: true,
                                    message: 'To date must be after From date.'
                                };
                            } else {
                                updatedErrors[endField] = { hasError: false };
                            }
                        }

                        return updatedErrors;
                    });
                }
            }
            return updatedFilter;
        });
    };

    const keyPress = (e: any) => {
        // Fetch jobs on 'enter' key press on term fields
        if (e.keyCode === 13) {
            fetchJobsBasedOnFilter();
        }
    }

    function stateFilters() {
        return (
            <Grid container spacing={1} style={{ width: 320 }} ml={0.4}>
                <Grid item xs={4}>
                    <FormControlLabel
                        control={<Checkbox checked={filterByState.filterNew} onChange={handleStateCheckboxChange} name="filterNew" />}
                        label="New"
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormControlLabel
                        control={<Checkbox checked={filterByState.filterActive} onChange={handleStateCheckboxChange} name="filterActive" />}
                        label="Active"
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormControlLabel
                        control={<Checkbox checked={filterByState.filterQueued} onChange={handleStateCheckboxChange} name="filterQueued" />}
                        label="Queued"
                    />
                </Grid>
            </Grid>
        )
    }

    function outcomeFilters() {
        return (
            <Grid container spacing={1} style={{ width: 320 }} ml={0.4}>
                <Grid item xs={4}>
                    <FormControlLabel
                        control={<Checkbox checked={filterByState.filterSuccess} onChange={handleStateCheckboxChange} name="filterSuccess" />}
                        label="Success"
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormControlLabel
                        control={<Checkbox checked={filterByState.filterFailed} onChange={handleStateCheckboxChange} name="filterFailed" />}
                        label="Failed"
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormControlLabel
                        control={<Checkbox checked={filterByState.filterCancelled} onChange={handleStateCheckboxChange} name="filterCancelled" />}
                        label="Cancelled"
                    />
                </Grid>
            </Grid>
        )
    }

    function dateFilters() {
        return (
            <Grid container spacing={1} alignItems='center' style={{ width: 320 }}>
                <Grid item xs={2} md={2} lg={2} xl={2} alignContent='center'>
                    <Checkbox checked={filterByDate.filterByCreationDate} onChange={handleDateCheckboxChange} name="filterByCreationDate" />
                </Grid>
                <Grid item xs={5} md={5} lg={5} xl={5}>
                    <DatePicker
                        defaultValue={filterByDate.creationStartDate}
                        label="Creation"
                        disabled={!filterByDate.filterByCreationDate}
                        value={filterByDate.creationStartDate}
                        onChange={handleDateChange('creationStartDate')}
                        format="MM/dd/yy"
                        slotProps={{
                            textField: {
                                variant: "standard",
                                error: errors.creationStartDate.hasError
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={5} md={5} lg={5} xl={5}>
                    <DatePicker
                        defaultValue={filterByDate.creationEndDate}
                        label=" "
                        disabled={!filterByDate.filterByCreationDate}
                        value={filterByDate.creationEndDate}
                        onChange={handleDateChange('creationEndDate')}
                        format="MM/dd/yy"
                        slotProps={{
                            textField: {
                                variant: "standard",
                                error: errors.creationEndDate.hasError
                            }
                        }}
                    />
                </Grid>
                <ErrorMessage error={errors.creationStartDate} />
                <ErrorMessage error={errors.creationEndDate} />
                <Grid item xs={2} alignContent='center' md={2} lg={2} xl={2}>
                    <Checkbox checked={filterByDate.filterByStartDate} onChange={handleDateCheckboxChange} name="filterByStartDate" />
                </Grid>
                <Grid item xs={5} md={5} lg={5} xl={5}>
                    <DatePicker
                        defaultValue={filterByDate.startStartDate}
                        label="Start"
                        disabled={!filterByDate.filterByStartDate}
                        value={filterByDate.startStartDate}
                        onChange={handleDateChange('startStartDate')}
                        format="MM/dd/yy"
                        slotProps={{
                            textField: {
                                variant: "standard",
                                error: errors.startStartDate.hasError
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={5} md={5} lg={5} xl={5}>
                    <DatePicker
                        defaultValue={filterByDate.startEndDate}
                        label=" "
                        disabled={!filterByDate.filterByStartDate}
                        value={filterByDate.startEndDate}
                        onChange={handleDateChange('startEndDate')}
                        format="MM/dd/yy"
                        slotProps={{
                            textField: {
                                variant: "standard",
                                error: errors.startEndDate.hasError
                            }
                        }}
                    />
                </Grid>
                <ErrorMessage error={errors.startStartDate} />
                <ErrorMessage error={errors.startEndDate} />
                <Grid item xs={2} alignContent='center' md={2} lg={2} xl={2}>
                    <Checkbox checked={filterByDate.filterByEndDate} onChange={handleDateCheckboxChange} name="filterByEndDate" />
                </Grid>
                <Grid item xs={5} md={5} lg={5} xl={5}>
                    <DatePicker
                        defaultValue={filterByDate.endStartDate}
                        label="End"
                        disabled={!filterByDate.filterByEndDate}
                        value={filterByDate.endStartDate}
                        onChange={handleDateChange('endStartDate')}
                        format="MM/dd/yy"
                        slotProps={{
                            textField: {
                                variant: "standard",
                                error: errors.endStartDate.hasError
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={5} md={5} lg={5} xl={5}>
                    <DatePicker
                        defaultValue={filterByDate.endEndDate}
                        label=" "
                        disabled={!filterByDate.filterByEndDate}
                        value={filterByDate.endEndDate}
                        onChange={handleDateChange('endEndDate')}
                        format="MM/dd/yy"
                        slotProps={{
                            textField: {
                                variant: "standard",
                                error: errors.endEndDate.hasError
                            }
                        }}
                    />
                </Grid>
                <ErrorMessage error={errors.endStartDate} />
                <ErrorMessage error={errors.endEndDate} />
            </Grid>
        )
    }

    function filterBar() {
        if (expanded) {
            return (
                <Grid container spacing={1} style={{ width: 320 }}>
                    <Grid container sx={{
                        justifyContent: "space-between",
                        alignItems: 'center',
                        padding: "10px"
                    }}>
                        <Grid item >
                            <Typography variant="h5">Filters </Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <Button color="primary" onClick={(event) => { setExpanded(false); setFilterBarElWidth(60); }}>
                                <ChevronLeftIcon fontSize="large" />
                            </Button>
                        </Grid>
                    </Grid>

                    {dateFilters()}
                    <Grid item xs={12}>
                        <TextField value={filterByTerm.jobIdTerm} fullWidth label="Job Id" type="search" size="small" variant="outlined" onKeyDown={keyPress} onChange={handleTermFilterChange('jobIdTerm')} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField value={filterByTerm.userEmailTerm} fullWidth label="Email" type="search" size="small" variant="outlined" onKeyDown={keyPress} onChange={handleTermFilterChange('userEmailTerm')} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField value={filterByTerm.ultimateSiteTerm} fullWidth label="Ultimate Site" size="small" type="search" variant="outlined" onKeyDown={keyPress} onChange={handleTermFilterChange('ultimateSiteTerm')} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField value={filterByTerm.userIdTerm} fullWidth label="User Id" type="search" size="small" variant="outlined" onKeyDown={keyPress} onChange={handleTermFilterChange('userIdTerm')} />
                    </Grid>
                    {stateFilters()}
                    {outcomeFilters()}
                    <Grid container spacing={2} sx={{
                        justifyContent: "flex-end",
                        alignItems: 'center',
                        paddingTop: "10px"
                    }}>
                        <Grid item>
                            <Button variant="contained" color="primary" sx={{ width: '100px' }} disableElevation onClick={(event) => handleTurnOffFilter(event)}>
                                Clear
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button variant="contained" color="secondary" sx={{ width: '100px' }} disableElevation onClick={() => fetchJobsBasedOnFilter()}>
                                Search
                            </Button>
                        </Grid>
                        <Grid item>
                            <Typography sx={{ fontStyle: 'italic' }}>If no filter is selected, the search will display jobs created within the last 30 days.</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            )
        }
        else {
            return (
                <Grid container spacing={0} style={{ width: 40 }}>
                    <Grid item xs={12}>
                        <Button color="primary" onClick={(event) => { setExpanded(true); setFilterBarElWidth(350); }}>
                            <FilterList fontSize="large" />
                        </Button>
                    </Grid>
                </Grid>
            )
        }
    }

    function exportFilteredDataToJson() {
        try {
            var jsonString = JSON.stringify(getFilteredJobs() ?? []);
            exportToJson(jsonString, `Jobs_${new Date().getTime()}.json`);
        } catch (err: any) {
            enqueueSnackbar(`Failed to export! Error: ${err.message}`, { variant: "error" });
        }
    }

    function getTableActions() {
        let actions: (Action<IJob> | ((rowData: IJob) => Action<IJob>))[] = [
            {
                tooltip: 'Refresh jobs',
                icon: 'refresh',
                isFreeAction: true,
                onClick: () => jobReport.refresh(jobQuery)
            },
        ]

        if (isAdminOrViewOnlyUser) {
            actions.push(
                (rowData) => ({
                    tooltip:
                        rowData.state !== JobState.New
                            ? "Download logs"
                            : undefined,
                    icon: "get_app",
                    disabled: rowData.state === JobState.New,
                    onClick: (event, rowData) =>
                        jobActions.downloadLogs(rowData),
                }),
                (rowData) => ({
                    tooltip:
                        isJobActiveOrQueued(rowData)
                            ? "Terminate Job"
                            : undefined,
                    icon: "cancel",
                    disabled: !isJobActiveOrQueued(rowData),
                    onClick: (event, rowData) => jobActions.terminate(rowData),
                })
            );
        }
        return actions;
    }

    const columns: Column<IJob>[] = [
        { title: "Name", field: "name", sorting: true, render: rowData => <JobLink id={rowData.id} name={rowData.name} /> },
        { title: "Type", field: "type", sorting: true },
        { title: "State", field: "state", cellStyle: { textTransform: 'capitalize' } },
        { title: "Outcome", field: "executionInformation.outcome" as any, sorting: true, cellStyle: { textTransform: 'capitalize' } },
        {
            title: "Email", field: "userDetails.email" as any, sorting: true,
            render: (rowData: IJob) => <EmailEllipsis email={rowData.userDetails.email} />
        },
        createDateColumn("Creation", "creationTime"),
        createDateColumn("Start", "executionInformation.startTime"),
        createDateColumn("End", "executionInformation.endTime", 'desc'),
        { title: "Location", field: "dataCenter.location" as any, sorting: true },
        {
            title: "Size", field: "executionInformation.inputInformation",
            render: (rowData) => getJobSize(rowData), sorting: true,
            filtering: false, editable: "never",
        },
        { title: "Ultimate", field: "ultimateSite", hidden: true, searchable: true },
        { title: "UserID", field: "userDetails.id", hidden: true, searchable: true },
        { title: "Job Id", field: "id", hidden: true, searchable: true }
    ];

    function getFilteredJobs(): IJob[] {
        if (!jobReport.hasData) return [];

        if (!searchText) return jobReport.data!;

        const normalizedQuery = searchText.trim().toLowerCase();

        var filteredJobs = jobReport.data!.filter(job => {
            return (
                job.name.toLowerCase().includes(normalizedQuery) ||
                job.type.toLowerCase().includes(normalizedQuery) ||
                job.state.toLowerCase().includes(normalizedQuery) ||
                (job.userDetails.email && job.userDetails.email.toLowerCase().includes(normalizedQuery)) ||
                job.userDetails?.id?.toLowerCase().includes(normalizedQuery) ||
                job.ultimateSite?.toString().toLowerCase().includes(normalizedQuery) ||
                job.id?.toString().toLowerCase().includes(normalizedQuery)
            );
        });
        return filteredJobs;
    }

    return (
        <div>
            <Grid container direction="row" justifyContent="space-evenly" spacing={1}>
                <Grid item mt={1} mx={0.7}>
                    {filterBar()}
                </Grid>
                <Grid item style={{ width: `calc(100% - ${filterBarElWidth}px)` }} mt={0.7}>
                    <MaterialTable<IJob>
                        title={<div><Typography variant="h5">Jobs</Typography></div>}
                        isLoading={jobReport.isFetching}
                        columns={columns}
                        data={getFilteredJobs() ?? []}
                        actions={getTableActions()}
                        options={{
                            filtering: false,
                            pageSize: pageSize,
                            pageSizeOptions: pageSizeOptions,
                            search: true,
                            sorting: true,
                            headerStyle: {
                                backgroundColor: "#9BA5AE",
                                color: "#000000",
                            },
                            padding: "dense",
                            exportAllData: true,
                            exportMenu: [
                                {
                                    label: "Export to JSON",
                                    exportFunc: (cols, datas) =>
                                        exportFilteredDataToJson(),
                                },
                            ],
                        }}
                        onSearchChange={(query) => setSearchText(query)}
                    />
                </Grid>
            </Grid>
        </div>
    );

}


