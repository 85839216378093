export const ApplicationName = "Reality Modeling Service";

// Time spans in milliseconds
export const OneSecInMilliseconds = 1000;
export const OneMinInMilliseconds = 60 * 1000;
export const ThirtyDaysInMilliseconds = 30 * 24 * 60 * OneMinInMilliseconds;

// Intervals
export const ActiveJobsRefreshInterval = 20 * OneSecInMilliseconds;
export const JobProgressRefreshInterval = 30 * OneSecInMilliseconds;
export const JobStepsRefreshInterval = 30 * OneSecInMilliseconds;
export const QueuedJobRefreshInterval = 3 * OneMinInMilliseconds;
