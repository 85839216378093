import * as React from 'react';
import MaterialTable from '@material-table/core';
import {ExportCsv} from '@material-table/exporters';
import {
    TextField,
    Button,
    Grid,
    Typography,
    Box,
    FormControlLabel,
    Checkbox,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useFetchApi } from '../utils/UseFetchApi';
import { IJob, JobLink, JobOutcome } from '../Model/Job';
import { dateToISOWithReducedPrecision } from '../utils/DateUtils';
import { IJobOverviewProps } from './JobsOverview';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { styled } from '@mui/system';


const StyledForm = styled('form')(()=> ({
    display: 'flex',
    flexWrap: 'wrap'
}))

const StyledTextField = styled(TextField)((props)=> ({
    width: '100vw',
    marginLeft: props.theme.spacing(1),
    marginRight: props.theme.spacing(1)
}))

const StyledButton = styled(Button)((props)=> ({
    margin: props.theme.spacing(1)
}))



export default function Profitability({pageSize,pageSizeOptions}: IJobOverviewProps) {
    const { enqueueSnackbar } = useSnackbar();
    const thirtyDaysInMilliseconds = 30 * 24 * 60 * 60 * 1000;
    const [ultimateSiteId, setUltimateSiteId] = React.useState("");
    const [startDate, setStartDate] = React.useState(new Date(Date.now() - thirtyDaysInMilliseconds));
    const [endDate, setEndDate] = React.useState(new Date(Date.now()));
    const [jobs, setJobs] = React.useState<IJob[]>([]);
    const [discardBentley, setDiscardBentley] = React.useState(false);
    const [statistics, setStatistics] = React.useState({
        allFailed: 0,
        allCancelled: 0,
        allSuccess: 0,
        ATFailed: 0,
        ATCancelled: 0,
        ATSuccess: 0,
        reconFailed: 0,
        reconCancelled: 0,
        reconSuccess: 0,
        exportFailed: 0,
        exportCancelled: 0,
        exportSuccess: 0
    });
    const [times, setTimes] = React.useState({
        allTime: 0,
        ATTime: 0,
        reconTime: 0,
        exportTime: 0
    });
    const fetchApi = useFetchApi<IJob[]>();

    function notifyError(message: string) {
        enqueueSnackbar(message, { variant: "error" });
    }

    const handleStartDateChange = (date: any) => {
        setStartDate(date);
    };

    const handleEndDateChange = (date: any) => {
        setEndDate(date);
    };

    const handleUltimateSiteIDChange = (siteId: any) => {
        setUltimateSiteId(siteId.target.value);
    };

    function getJobQueryFilter(startDate: Date, endDate: Date, ultimateSite: string) {
        return `finishStartTime=${dateToISOWithReducedPrecision(startDate)}` +
            `&finishEndTime=${dateToISOWithReducedPrecision(endDate)}` +
            `&ultimateSite=${ultimateSite}` +
            `&state=Completed`;
    }

    function getUrl(startDate: Date, endDate: Date, ultimateSite: string) {
        return window.location.origin + '/api/v2/jobs/report?' + getJobQueryFilter(startDate, endDate, ultimateSite);
    }

    function submit() {
        submitAsync().then((jobs) => {
            if (jobs && jobs.length > 0)
                setJobs(jobs)
            })
        .catch(error => {
            if (error instanceof Error) {
                notifyError(error.message);
            }
        });
    }

    async function submitAsync() {
        enqueueSnackbar("Calculating profitability", { variant: "info" });
        try {
            let jobs = await fetchApi.run(getUrl(startDate, endDate, ultimateSiteId));

            if (!jobs)
                throw Error("Unknown error");

            enqueueSnackbar("Profitability successfully retrieved !", { variant: "success" });

            if (discardBentley) {
                jobs = jobs.filter((i: IJob) => !(i.ultimateSite === "1001389117"));
            }

            calculateData(jobs);

            return jobs;
        }
        catch (error) {
            let errorMessage = "Bill Reporting failed ";
            if (error instanceof Error) {
                errorMessage += error.message;
            }
            notifyError(errorMessage);
        }
    }

    function CSVButton() {
        return;
    }

    function ProcessingUnits(jobs: IJob[]) {
        let processingUnits = 0;
        jobs.forEach((job) => {
            processingUnits += job.executionInformation?.estimatedUnits ?? 0;
        })
        return processingUnits;
    }

    const handleCheckboxChange = (event: any) => {
        setDiscardBentley(event.target.checked);
    };

    function calculateData(jobs: IJob[]) {
        let tmpAllFailed = 0;
        let tmpAllCancelled = 0;
        let tmpAllSuccess = 0;
        let tmpATFailed = 0;
        let tmpATCancelled = 0;
        let tmpATSuccess = 0;
        let tmpReconFailed = 0;
        let tmpReconCancelled = 0;
        let tmpReconSuccess = 0;
        let tmpExportFailed = 0;
        let tmpExportCancelled = 0;
        let tmpExportSuccess = 0;
        let tmpAllTime = 0;
        let tmpATTime = 0;
        let tmpReconTime = 0;
        let tmpExportTime = 0;
        jobs.forEach((job) => {
            tmpAllTime += job.executionInformation?.computeTime ?? 0;
            if (job.executionInformation?.outcome === JobOutcome.Failed) {
                tmpAllFailed++;
                if (job.type === "Calibration") {
                    tmpATFailed++;
                    tmpATTime += job.executionInformation?.computeTime ?? 0;
                }
                else if (job.type === "Full" && job.executionInformation?.inputInformation?.calibrationFromCache && job.executionInformation?.inputInformation?.reconstructionFromCache) {
                    tmpExportFailed++;
                    tmpExportTime += job.executionInformation?.computeTime ?? 0;
                }
                else {
                    tmpReconFailed++;
                    tmpReconTime += job.executionInformation?.computeTime ?? 0;
                }
            }
            else if (job.executionInformation?.outcome === JobOutcome.Cancelled) {
                tmpAllCancelled++;
                if (job.type === "Calibration") {
                    tmpATCancelled++;
                    tmpATTime += job.executionInformation?.computeTime ?? 0;
                }
                else if (job.type === "Full" && job.executionInformation?.inputInformation?.calibrationFromCache && job.executionInformation?.inputInformation?.reconstructionFromCache) {
                    tmpExportCancelled++;
                    tmpExportTime += job.executionInformation?.computeTime ?? 0;
                }
                else {
                    tmpReconCancelled++;
                    tmpReconTime += job.executionInformation?.computeTime ?? 0;
                }
            }
            else {
                tmpAllSuccess++;
                if (job.type === "Calibration") {
                    tmpATSuccess++;
                    tmpATTime += job.executionInformation?.computeTime ?? 0;
                }
                else if (job.type === "Full" && job.executionInformation?.inputInformation?.calibrationFromCache && job.executionInformation?.inputInformation?.reconstructionFromCache) {
                    tmpExportSuccess++;
                    tmpExportTime += job.executionInformation?.computeTime ?? 0;
                }
                else {
                    tmpReconSuccess++;
                    tmpReconTime += job.executionInformation?.computeTime ?? 0;
                }
            }
        });
        setStatistics({
            allFailed: tmpAllFailed,
            allCancelled: tmpAllCancelled,
            allSuccess: tmpAllSuccess,
            ATFailed: tmpATFailed,
            ATCancelled: tmpATCancelled,
            ATSuccess: tmpATSuccess,
            reconFailed: tmpReconFailed,
            reconCancelled: tmpReconCancelled,
            reconSuccess: tmpReconSuccess,
            exportFailed: tmpExportFailed,
            exportCancelled: tmpExportCancelled,
            exportSuccess: tmpExportSuccess
        });

        setTimes({
            allTime: tmpAllTime,
            ATTime: tmpATTime,
            reconTime: tmpReconTime,
            exportTime: tmpExportTime
        });
    }

    function createBox(fontsize: number, boxMargin: string, dataLabel: string, dataValue: any = "") {
        const data = `${dataLabel} ${dataValue}`;
        return (<Box component="div" display="block" style={{ fontSize: fontsize, margin: boxMargin }}>{data}</Box>);
    }

    function DisplayResult() {
        if (jobs && jobs.length > 0) {
            return (
                <Grid container>
                    <Grid item xs={12} md={12} lg={12} xl={12}>
                        <Grid container spacing={3}>
                            <Grid item xs>
                                {createBox(20, '10px', "Processing Units :", ProcessingUnits(jobs))}
                            </Grid>
                            <Grid item xs>
                                {createBox(15, '5px', "Jobs :", jobs.length)}
                                {createBox(15, '5px', "Total Time in hours :", (times.allTime / 3600).toFixed(5))}
                                {createBox(15, '5px', "Failed :", statistics.allFailed)}
                                {createBox(15, '5px', "Cancelled :", statistics.allCancelled)}
                                {createBox(15, '5px', "Success :", statistics.allSuccess)}
                            </Grid>
                            <Grid item xs>
                                {createBox(20, '5px', "AT :")}
                                {createBox(15, '5px', "AT Time in hours :", (times.ATTime / 3600).toFixed(5))}
                                {createBox(15, '5px', "Failed :", statistics.ATFailed)}
                                {createBox(15, '5px', "Cancelled :", statistics.ATCancelled)}
                                {createBox(15, '5px', "Success :", statistics.ATSuccess)}
                            </Grid>
                            <Grid item xs>
                                {createBox(20, '5px', "Recon (Full + Recon Only) :")}
                                {createBox(15, '5px', "Recon Time in hours :", (times.reconTime / 3600).toFixed(5))}
                                {createBox(15, '5px', "Failed :", statistics.reconFailed)}
                                {createBox(15, '5px', "Cancelled :", statistics.reconCancelled)}
                                {createBox(15, '5px', "Success :", statistics.reconSuccess)}
                            </Grid>
                            <Grid item xs>
                                {createBox(20, '5px', "Export Only :")}
                                {createBox(15, '5px', "Export Time in hours :", (times.exportTime / 3600).toFixed(5))}
                                {createBox(15, '5px', "Failed :", statistics.exportFailed)}
                                {createBox(15, '5px', "Cancelled :", statistics.exportCancelled)}
                                {createBox(15, '5px', "Success :", statistics.exportSuccess)}
                            </Grid>
                        </Grid>

                    </Grid>
                    <Grid item xs={12} md={12} lg={12} xl={12}>
                        <MaterialTable<IJob>
                            title={<div><Typography variant="h5"><>Jobs&nbsp;{CSVButton()}</></Typography></div>}
                            isLoading={fetchApi.isFetching}
                            columns={[
                                { title: "Name", field: "name", sorting: true, render: rowData => <JobLink id={rowData.id} name={rowData.name} /> },
                                { title: "ID", field: "id", sorting: true },
                                { title: "Processing Time", field: "executionInformation.computeTime" as any, sorting: false, render: rowData => ((rowData.executionInformation?.computeTime ?? 0) / 3600).toFixed(5)},
                                { title: "Type", field: "type", sorting: true },
                                { title: "Calibration From Cache", field: "executionInformation.inputInformation.calibrationFromCache"},
                                { title: "Reconstruction From Cache", field: "executionInformation.inputInformation.reconstructionFromCache"},
                                { title: "Outcome", field: "executionInformation.outcome" as any, sorting: true, lookup: { "Success": 'Success', "Failed": 'Failed', "Cancelled": 'Cancelled' } },
                                { title: "Outputs", field: "settings.outputs.length" as any, sorting: true}
                            ]}
                            data={jobs ?? []}
                            options={{
                                filtering: false,
                                pageSize: pageSize,
                                pageSizeOptions: pageSizeOptions,
                                search: false,
                                sorting: true,
                                headerStyle: { backgroundColor: '#9BA5AE', color: '#000000' },
                                padding: 'dense',
                                exportAllData: true,
                                exportMenu: [{
                                    label: 'Export to CSV',
                                    exportFunc: (cols, datas) => ExportCsv(cols, datas, 'Profitability')
                                }]
                            }}
                        />
                    </Grid>
                </Grid>
                );
        }
        else {
            return <div />
        }
    }

    return (
        <div>
            <StyledForm  noValidate autoComplete="off">
                <Grid container spacing={1} mt={0.5} mx={0} style={{ width: '100%'}}>
                    <Grid item xs={2}>
                        <DatePicker
                                            
                            label="Start Date"
                            value = {startDate}
                            onChange={handleStartDateChange}
                            format="MM/dd/yyyy"
                            slotProps={{
                                textField: {
                                variant: "standard",
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <DatePicker
                                            
                            label="End Date"
                            value = {endDate}
                            onChange={handleStartDateChange}
                            format="MM/dd/yyyy"
                            slotProps={{
                                textField: {
                                variant: "standard",
                                }
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={1}>
                    <Grid item xs={4}>
                        <StyledTextField
                            type="text"
                            id="ultimate-site-id"
                            label="Ultimate Site ID"
                            style={{ width: '100%' }}
                            margin="normal"
                            onChange={handleUltimateSiteIDChange}
                            InputProps={{ required: false }}
                            variant="standard"
                        />
                    </Grid>
                    <Grid item style={{padding: 0}}>
                        <FormControlLabel
                            control={<Checkbox checked={discardBentley} onChange={handleCheckboxChange} name="discardBentley" />}
                            label="Discard Bentley Usage"
                            labelPlacement="top"
                            sx={{mt:'16px'}}
                        />
                    </Grid>
                </Grid>
                <StyledButton variant="contained" color="secondary"  onClick={submit}>
                    Calculate
                </StyledButton>
            </StyledForm>
            <DisplayResult />
        </div>
        );
}
