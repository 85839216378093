import * as React from 'react';
import { TextField, Button } from '@mui/material';
import { useSnackbar } from 'notistack';
import { IAccessInfo } from '../Model/AccessInfo';
import { useFetchApi } from '../utils/UseFetchApi';
import { styled } from '@mui/system';

const StyledForm = styled('form')(()=> ({
    display: 'flex',
    flexWrap: 'wrap'
}))

const StyledTextField = styled(TextField)((props)=> ({
    width: '100vw',
    marginLeft: props.theme.spacing(1),
    marginRight: props.theme.spacing(1) 
}))

const StyledButton = styled(Button)((props)=> ({
     margin:props.theme.spacing(1)
}))



export default function CheckUserAccess() {
    const { enqueueSnackbar } = useSnackbar();
    const [userId, setUserId] = React.useState("");
    const [ultimateSiteId, setUltimateSiteId] = React.useState("");
    const [currentAccessInfo, setCurrentAccessInfo] = React.useState("");
    const fetchApi = useFetchApi<IAccessInfo>();

    const onUserIdChanged = () => (event: React.ChangeEvent<HTMLInputElement>) => {
        setUserId(event.target.value);
    }

    const onUltimateSiteIdChanged = () => (event: React.ChangeEvent<HTMLInputElement>) => {
        setUltimateSiteId(event.target.value);
    }

    function notifyError(message: string) {
        enqueueSnackbar(message, { variant: "error" });
    }

    async function submitAsync() {
        // CALL NEW API ROUTE : CHECK USER ACCESS
        enqueueSnackbar("Checking Access Information from supplied data", { variant: "info" });

        try {
            const accessInfo = await fetchApi.run(window.location.origin + "/api/v2/Cost/checkaccess?userId=" + userId + "&ultimateSiteId=" + ultimateSiteId);

            if (!accessInfo)
                throw Error("Unknow error");

            enqueueSnackbar("Access Info successfully retrieved !", { variant: "success" });

            return accessInfo;
        }
        catch (error) {
            let errorMessage = "Checking Access Information from Ultimate Site Id failed ";
            if (error instanceof Error) {
                errorMessage += error.message;
            }
            notifyError(errorMessage);
        }
    }

    function submit() {
        submitAsync().then((accessInfo) => setCurrentAccessInfo(JSON.stringify(accessInfo, null, '\t')))
            .catch(error => {
                // Using this catch for JSON.stringify. It might throw error.
                let errorMessage = "Checking Access Information from Ultimate Site Id failed ";
                if (error instanceof Error) {
                    errorMessage += error.message;
                }
                notifyError(errorMessage);
            });
    }

    function DisplayResult() {
        const accessInfo = currentAccessInfo;
        if (accessInfo !== "") {
            return (
                <div>
                    <StyledTextField
                        id="outlined-multiline-static"
                        label="Access Info"
                        multiline
                        rows="5"
                        value={accessInfo}
                        margin="normal"
                        variant="outlined"
                        InputProps={{ readOnly: true }}
                    />
                </div>
            );
        }
        else {
            return <div />
        }
    }

    return (
        <div>
            <StyledForm  noValidate autoComplete="off">
                <StyledTextField
                    type="text"
                    id="user-id"
                    label="User ID"
                    style={{ width: '100%' }}
                    margin="normal"
                    onChange={onUserIdChanged()}
                    InputProps={{ required: true }}
                    variant="standard"
                />
                <StyledTextField
                    type="text"
                    id="ultimate-site-id"
                    label="Ultimate Site ID"
                    style ={{width: '100%'}}
                    margin="normal"
                    onChange={onUltimateSiteIdChanged()}
                    InputProps={{ required: true }}
                    variant="standard"
                />
                <StyledButton variant="contained" color="secondary"  onClick={submit}>
                    Check User Access
                </StyledButton>
            </StyledForm>
            <DisplayResult />
        </div>

    );
}
