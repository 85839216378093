import * as React from 'react';
import MaterialTable from '@material-table/core';
import {ExportCsv} from '@material-table/exporters';
import {
    TextField,
    Button,
    Grid,
    Typography,
    Box,
    FormControlLabel,
    Checkbox,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useFetchApi } from '../utils/UseFetchApi';
import { IJob, JobLink } from '../Model/Job';
import { dateToISOWithReducedPrecision, createDateColumn } from '../utils/DateUtils';
import { IJobOverviewProps } from './JobsOverview';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { styled } from '@mui/system';

const StyledForm = styled('form')(()=> ({
    display: 'flex',
    flexWrap: 'wrap'
}))

const StyledTextField = styled(TextField)((props)=> ({
    width: '100vw',
    marginLeft: props.theme.spacing(1),
    marginRight: props.theme.spacing(1)
}))

const StyledButton = styled(Button)((props)=> ({
    margin: props.theme.spacing(1)
}))

export default function Reporting({pageSize,pageSizeOptions}: IJobOverviewProps) {
    const { enqueueSnackbar } = useSnackbar();
    const thirtyDaysInMilliseconds = 30 * 24 * 60 * 60 * 1000;
    const [ultimateSiteId, setUltimateSiteId] = React.useState("");
    const [startDate, setStartDate] = React.useState(new Date(Date.now() - thirtyDaysInMilliseconds));
    const [endDate, setEndDate] = React.useState(new Date(Date.now()));
    const [jobs, setJobs] = React.useState<IJob[]>([]);
    const [discardBentley, setDiscardBentley] = React.useState(false);
    const fetchApi = useFetchApi<IJob[]>();

    function notifyError(message: string) {
        enqueueSnackbar(message, { variant: "error" });
    }

    const handleStartDateChange = (date: any) => {
        setStartDate(date);
    };

    const handleEndDateChange = (date: any) => {
        setEndDate(date);
    };

    const handleUltimateSiteIDChange = (siteId: any) => {
        setUltimateSiteId(siteId.target.value);
    };

    function getJobQueryFilter(startDate: Date, endDate: Date, ultimateSite: string) {
        return `finishStartTime=${dateToISOWithReducedPrecision(startDate)}` +
            `&finishEndTime=${dateToISOWithReducedPrecision(endDate)}` +
            `&ultimateSite=${ultimateSite}` +
            `&state=Completed`;
    }

    function getUrl(startDate: Date, endDate: Date, ultimateSite: string) {
        return window.location.origin + '/api/v2/jobs/report?' + getJobQueryFilter(startDate, endDate, ultimateSite);
    }

    function submit() {
        submitAsync().then((jobs) => {
            if (jobs)
                setJobs(jobs)
            })
        .catch(error => {
            if (error instanceof Error) {
                notifyError(error.message);
            }
        });
    }

    async function submitAsync() {
        enqueueSnackbar("Calculating for bill reporting", { variant: "info" });
        try {
            let jobs = await fetchApi.run(getUrl(startDate, endDate, ultimateSiteId));

            if (!jobs)
                throw Error("Unknown error");

            enqueueSnackbar("Billing information successfully retrieved !", { variant: "success" });

            if (discardBentley) {
                jobs = jobs.filter((i: IJob) => !(i.ultimateSite === "1001389117"));
            }

            return jobs;
        }
        catch (error) {
            let errorMessage = "Bill Reporting failed ";
            if (error instanceof Error) {
                errorMessage += error.message;
            }
            notifyError(errorMessage);
        }
    }

    function CSVButton() {
        return;
    }

    function ProcessingUnits(jobs: IJob[]) {
        let processingUnits = 0;
        jobs.forEach((job) => {
            processingUnits += job.executionInformation?.estimatedUnits ?? 0;
        })
        return processingUnits;
    }

    function getGigaPixelsAT(job: IJob) {
        return job.executionInformation?.inputInformation?.gigaPixelsAT ?? 0;
    }

    function getGigaPixelsRecons(job: IJob) {
        return job.executionInformation?.inputInformation?.gigaPixelsRecons ?? 0;
    }

    function getMegaPoints(job: IJob) {
        return job.executionInformation?.inputInformation?.megaPoints ?? 0;
    }

    function getPointCloudCount(job: IJob) {
        return job.executionInformation?.inputInformation?.pointCloudCount ?? 0;
    }

    const handleCheckboxChange = (event: any) => {
        setDiscardBentley(event.target.checked);
    };

    function DisplayResult() {
        if (jobs && jobs.length > 0) {
            return (
                <div>
                    <div>
                        <Box component="div" display="inline" style={{ fontSize: 20, margin: '10px' }}>Processing Units : {ProcessingUnits(jobs)}</Box>
                    </div>
                    <Grid  >
                        <MaterialTable<IJob>
                            title={<div><Typography variant="h5"><>Jobs&nbsp;{CSVButton()}</></Typography></div>}
                            isLoading={fetchApi.isFetching}
                            columns={[
                                { title: "Name", field: "name", sorting: true, render: rowData => <JobLink id={rowData.id} name={rowData.name} /> },
                                { title: "ID", field: "id", sorting: true },
                                { title: "Type", field: "type", sorting: true },
                                { title: "Outcome", field: "executionInformation.outcome" as any, sorting: true, lookup: { "Success": 'Success', "Failed": 'Failed', "Cancelled": 'Cancelled' } },
                                { title: "Email", field: "userDetails.email" as any, sorting: true },
                                { title: "UserId", field: "userDetails.id" as any, sorting: true },
                                createDateColumn("Submission", "creationTime"),
                                createDateColumn("Completed", "executionInformation.endTime"),
                                { title: "Units", field: "executionInformation.estimatedUnits" as any, sorting: true },
                                { title: "Gpix", field: "executionInformation.inputInformation.gigaPixelsAT" as any, sorting: false, render: rowData => <div>{getGigaPixelsAT(rowData)}</div> },
                                { title: "Gpix Recon", field: "executionInformation.inputInformation.gigaPixelsRecons" as any, sorting: false, render: rowData => <div>{getGigaPixelsRecons(rowData)}</div> },
                                { title: "MPoints", field: "executionInformation.inputInformation.megaPoints" as any, sorting: false, render: rowData => <div>{getMegaPoints(rowData)}</div> },
                                { title: "Point Clouds", field: "executionInformation.inputInformation.pointCloudCount" as any, sorting: false, render: rowData => <div>{getPointCloudCount(rowData)}</div> }
                            ]}
                            data={jobs ?? []}
                            options={{
                                filtering: false,
                                pageSize: pageSize,
                                pageSizeOptions: pageSizeOptions,
                                search: false,
                                sorting: true,
                                headerStyle: { backgroundColor: '#9BA5AE', color: '#000000' },
                                padding: 'dense',
                                exportAllData: true,
                                exportMenu: [{
                                    label: 'Export to CSV',
                                    exportFunc: (cols, datas) => ExportCsv(cols, datas, 'Reporting_' + ultimateSiteId)
                                }]
                            }}
                        />
                    </Grid>
                </div>
                );
        }
        else {
            return <div />
        }
    }

    return (
        <div>
            <StyledForm noValidate autoComplete="off">
                <StyledTextField
                    type="text"
                    id="ultimate-site-id"
                    label="Ultimate Site ID"
                    style={{ width: '100%' }}
                    margin="normal"
                    onChange={handleUltimateSiteIDChange}
                    InputProps={{ required: true }}
                    variant="standard"
                />
                <Grid container spacing={1} style={{ width: '100%' }} mx={0} mt={0.5}>
                    <Grid item xs={2}>
                        <DatePicker
                                            
                            label="Start Date"
                            value = {startDate}
                            onChange={handleStartDateChange}
                            format="MM/dd/yyyy"
                            slotProps={{
                                textField: {
                                variant: "standard",
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={2} >
                        <DatePicker
                                            
                            label="End Date"
                            value = {endDate}
                            onChange={handleEndDateChange}
                            format="MM/dd/yyyy"
                            slotProps={{
                                textField: {
                                variant: "standard",
                                }
                            }}
                        />
                    </Grid>
                    <Grid item style={{padding: 0}}>
                        <FormControlLabel
                            control={<Checkbox checked={discardBentley} onChange={handleCheckboxChange} name="discardBentley" />}
                            label="Discard Bentley Usage"
                            labelPlacement="top"
                        />
                    </Grid>
                </Grid>
                <StyledButton variant="contained" color="secondary" onClick={submit}>
                    Bill
                </StyledButton>
            </StyledForm>
            <Grid container >
                <Grid item xs={12} md={12} lg={12} xl={12}>
                   <DisplayResult />
               </Grid>
            </Grid>
            
        </div>
        );
}
